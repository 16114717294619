import React, { useContext } from 'react'
import { Switch } from 'react-router-dom'
import PrivatedRoute from 'src/components/PrivatedRoute'
import { AuthContext } from 'src/context/AuthenticationContext'
import { AbilityContext } from 'src/context/Can'
import { MainLayout } from 'src/layouts'
import {
  DOCUMENT_REGISTRATION,
  DOCUMENT_PORTAL,
  DOCUMENT_SETUP,
  CERTIFICATION_EVENTS,
  DOCUMENT_REPOSITORY,
  DASHBOARD,
  THIRD_PARTY_REPOSITORY,
  DIGITAL_SELLER_SURVEY_DETAIL,
  DIGITAL_SELLER_SURVEY,
  EXTERNAL_DIGITAL_SELLER_SURVEY_DETAIL,
  SURVEY_BUILDER,
  CERTIFICATION_MANAGER,
  CORPORATE_SOP,
  CORPORATE_SOP_DETAIL,
  LOAD_SOP,
  CORPORATE_SOP_REPOSITORY,
} from 'src/routes'
import { Permissions } from 'src/api/models/permissions'
import { buildAbilityFor } from 'src/configs/ability'
import { PermissionCodeAccess } from 'src/utils/constants'

import { GlobalProvider } from 'src/context/GlobalContext'

import {
  DigitalSellerSurvey,
  DigitalSellerSurveyDetails,
  ExternalDigitalSellerSurveyDetails,
  SurveyBuilder,
  buildAbilityFor as buildAbilityForDSS,
  AbilityContext as AbilityContextDSS,
} from 'everchain-digital-forms'
import DocumentSetupMain from '../DocumentSetup/DocumentSetupMain'
import AccessDenied from '../AccessDenied'
import DocumentPortal from '../DocumentPortal/DocumentPortal'
import DocumentRegistrationMain from '../NewDocumentRegistration/DocumentRegistrationMain'
import CertificationEvents from '../AuditTrail/CertificationEvents'
import { Dashboard } from '../Dashboard'
import { DocumentRepository } from '../DocumentRepository'
import { ThirdPartyRepository } from '../ThirdPartyRepository'
import { CertificationManager } from '../CertificationManager'
import { CorporateSOP, CorporateSOPRepository } from '../CorporateSOP'
import CorporateSOPDetail from '../CorporateSOP/CorporateSOPDetail'
import LoadSOP from '../CorporateSOP/LoadSOP'

interface AbilityProviderProps {
  children: React.ReactNode
  permissionValues: Permissions[]
}

const AbilityProvider = ({
  children,
  permissionValues,
}: AbilityProviderProps) => {
  const { userPermissions } = useContext(AuthContext)
  const ability = buildAbilityFor(userPermissions.type, permissionValues)
  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  )
}

const AbilityDSSProvider = ({
  children,
  permissionValues,
}: AbilityProviderProps) => {
  const { userPermissions } = useContext(AuthContext)
  const ability = buildAbilityForDSS(userPermissions.type, permissionValues)
  return (
    <AbilityContextDSS.Provider value={ability as any}>
      {children}
    </AbilityContextDSS.Provider>
  )
}

interface PermissionReduce {
  [field: string]: boolean
}
const permissionReduce = (
  permissionValues: Permissions[] = []
): PermissionReduce => {
  return permissionValues.reduce(
    (acc: any, item: Permissions) => ({ ...acc, [item.code]: item.value }),
    {}
  )
}

interface AppProps {
  managePermission: Permissions[]
}
const App = ({ managePermission }: AppProps) => {
  const { userPermissions } = useContext(AuthContext)
  return (
    <AbilityProvider permissionValues={managePermission}>
      <AbilityDSSProvider permissionValues={managePermission}>
        <GlobalProvider>
          <MainLayout>
            <Switch>
              <PrivatedRoute path={LOAD_SOP}>
                {permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementCorporateSOP_Upload
                ] ? (
                  <LoadSOP />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>
              <PrivatedRoute path={CORPORATE_SOP}>
                {userPermissions.type === 'internal' &&
                permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementCorporateSOP_Access
                ] ? (
                  <CorporateSOP />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>
              <PrivatedRoute path={`${CORPORATE_SOP_DETAIL}/:id`}>
                {userPermissions.type === 'internal' &&
                permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementCorporateSOP_Access
                ] ? (
                  <CorporateSOPDetail />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>
              <PrivatedRoute path={`${CORPORATE_SOP_REPOSITORY}`}>
                {permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementCorporateSOP_Access
                ] ? (
                  <CorporateSOPRepository />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>
              <PrivatedRoute path={DOCUMENT_SETUP}>
                {userPermissions.type === 'internal' &&
                permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal
                ] ? (
                  <DocumentSetupMain />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>

              <PrivatedRoute path={DASHBOARD}>
                {userPermissions.type === 'internal' &&
                permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal
                ] ? (
                  <Dashboard />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>

              <PrivatedRoute path={CERTIFICATION_EVENTS}>
                {userPermissions.type === 'internal' &&
                permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal
                ] ? (
                  <CertificationEvents />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>

              <PrivatedRoute path={DOCUMENT_REGISTRATION}>
                {permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal
                ] ||
                permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementSystem_Basic_External
                ] ? (
                  <DocumentRegistrationMain />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>
              <PrivatedRoute path={DOCUMENT_PORTAL}>
                {permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal
                ] ? (
                  <DocumentPortal />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>
              <PrivatedRoute path={THIRD_PARTY_REPOSITORY}>
                {permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal
                ] ||
                permissionReduce(managePermission)[
                  PermissionCodeAccess
                    .ComplianceManagementSystem_Basic_RepositoryAccess
                ] ? (
                  <ThirdPartyRepository />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>
              <PrivatedRoute path={DOCUMENT_REPOSITORY}>
                {permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal
                ] ||
                permissionReduce(managePermission)[
                  PermissionCodeAccess
                    .ComplianceManagementSystem_Basic_RepositoryAccess
                ] ? (
                  <DocumentRepository />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>
              <PrivatedRoute exact path={`${DIGITAL_SELLER_SURVEY}`}>
                {permissionReduce(managePermission)[
                  PermissionCodeAccess
                    .ComplianceManagementSystem_Basic_ManageDocumentSetup
                ] ||
                permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementSystem_Basic_External
                ] ? (
                  <DigitalSellerSurvey />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>
              <PrivatedRoute
                path={`${DIGITAL_SELLER_SURVEY_DETAIL}/:id?/:businessId?`}
              >
                {permissionReduce(managePermission)[
                  PermissionCodeAccess
                    .ComplianceManagementSystem_Basic_ManageDocumentSetup
                ] ||
                permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementSystem_Basic_External
                ] ? (
                  <DigitalSellerSurveyDetails />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>
              <PrivatedRoute
                path={`${EXTERNAL_DIGITAL_SELLER_SURVEY_DETAIL}/:id?/:businessId?`}
              >
                {permissionReduce(managePermission)[
                  PermissionCodeAccess
                    .ComplianceManagementSystem_Basic_ManageDocumentSetup
                ] ||
                permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementSystem_Basic_External
                ] ? (
                  <ExternalDigitalSellerSurveyDetails />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>
              <PrivatedRoute exact path={`${SURVEY_BUILDER}/:id?`}>
                {permissionReduce(managePermission)[
                  PermissionCodeAccess
                    .ComplianceManagementSystem_Basic_ManageDocumentSetup
                ] ||
                permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementSystem_Basic_External
                ] ? (
                  <SurveyBuilder />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>
              <PrivatedRoute path={CERTIFICATION_MANAGER}>
                {userPermissions.type === 'internal' &&
                permissionReduce(managePermission)[
                  PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal
                ] ? (
                  <CertificationManager />
                ) : (
                  <AccessDenied />
                )}
              </PrivatedRoute>
            </Switch>
          </MainLayout>
        </GlobalProvider>
      </AbilityDSSProvider>
    </AbilityProvider>
  )
}

export default App
