import {
  Box,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'
import axios from 'axios'
import React, { useContext, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import Loader from 'src/components/Loader'
import { GridColumn as KendoGridColumn } from '@progress/kendo-react-grid'
import { StyledKendoGrid as KendoGrid } from 'src/styles/kendoGridStyle'
import { renderDateTime } from 'src/utils/formatKendoColumns'
import { GetApp } from '@material-ui/icons'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'

interface ISOPDraft {
  sop: any
  department: string
  isCompliance: boolean
}

const SOPDraft: React.FC<ISOPDraft> = ({
  sop,
  department,
  isCompliance,
}: ISOPDraft) => {
  const grid = useRef<any>(null)
  const userToken = GetAccessToken()
  const [sopToDownload, setSopToDownload] = useState<any | null>(null)
  const ability = useContext(AbilityContext)
  const canDownloadAnyVersion =
    (isCompliance || department === sop.department) &&
    ability.can(
      PermissionCodeAccess.ComplianceManagementCorporateSOP_Download,
      'any'
    )
  useQuery({
    queryKey: ['downloadSOP', sopToDownload],
    cacheTime: 0,
    queryFn: async () => {
      return axios({
        method: 'GET',
        url: `${BACKEND_URL}/corporateSOP/DownloadSOP`,
        params: {
          blobUri: sopToDownload.blobUri,
          name: sopToDownload.fileName,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setSopToDownload(null)
        window.location.href = result.data || ''
      })
    },
    enabled: sopToDownload !== null,
  })
  const handleDownloadFile = (e: any) => {
    setSopToDownload(e)
  }

  const getSOPDrafts = useQuery({
    queryKey: ['getSOPDrafts', sop],
    cacheTime: 0,
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/corporateSOP/getSOPDrafts`,
        params: {
          id: sop.id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result
      })
    },
  })

  const SOPDrafts = getSOPDrafts?.data || []
  const columns: any[] = [
    {
      field: 'createdDate',
      title: 'Created Date',
      cell: renderDateTime,
      show: true,
    },
    {
      field: 'userName',
      title: 'User',
      show: true,
    },
    {
      field: 'actions',
      title: ' ',
      show: true,
      width: '80px',
      cell: (props: any) => {
        return (
          <>
            <td className="k-command-cell">
              <Tooltip title="Download">
                <span>
                  <IconButton
                    disabled={!canDownloadAnyVersion}
                    color="primary"
                    aria-label="Download"
                    onClick={(e: any) => handleDownloadFile(props.dataItem)}
                  >
                    <GetApp />
                  </IconButton>
                </span>
              </Tooltip>
            </td>
          </>
        )
      },
    },
  ]

  return (
    <>
      <Box padding={5} mt={4}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle2">SOP Draft Versions</Typography>
          </Grid>
          <Paper
            style={{
              padding: 5,
              width: '100%',
              minHeight: '200px',
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              {getSOPDrafts.isLoading && <Loader />}
              <KendoGrid
                ref={grid}
                scrollable="scrollable"
                data={SOPDrafts ?? []}
                style={{ minHeight: '200px', maxHeight: '400px' }}
              >
                {columns.reduce((acc, item, idx) => {
                  const component = [...acc]

                  item.show &&
                    component.push(
                      <KendoGridColumn
                        key={item.field + item.title}
                        field={item.field}
                        title={item.title}
                        cell={item.cell}
                        width={item.width}
                      />
                    )

                  return component
                }, [])}
              </KendoGrid>
            </Grid>
          </Paper>
        </Grid>
      </Box>
    </>
  )
}

export default SOPDraft
