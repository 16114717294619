/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'
import { Permissions } from 'src/api/models/permissions'

type Actions =
  | '002'
  | '002.000'
  | '002.000.000'
  | '002.000.001'
  | '002.000.002'
  | '002.000.003'
  | '002.000.004'
  | '002.000.005'
  | '002.000.006'
  | '002.000.007'
  | '002.000.008'
  | '002.001'
  | '002.001.000'
  | '002.001.001'
  | '002.001.002'
  | '002.001.003'
  | '002.001.004'
  | '002.001.005'
type Subjects = 'any'

export type AppAbility = Ability<[Actions, Subjects]>

// eslint-disable-next-line @typescript-eslint/no-redeclare, no-redeclare
export const AppAbility = Ability as AbilityClass<AppAbility>

export default function defineRulesFor(
  role: string,
  permissionValues: Permissions[]
) {
  const { can, cannot, rules } = new AbilityBuilder<AppAbility>(AppAbility)

  permissionValues.forEach((v) => {
    if (v.value) {
      can(v.code as Actions, 'any')
    } else {
      cannot(v.code as Actions, 'any')
    }
  })

  return rules
}

export function buildAbilityFor(
  role: string,
  permissionValues: Permissions[]
): AppAbility {
  return new AppAbility(defineRulesFor(role, permissionValues))
}
